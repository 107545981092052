<template>
  <div>
    <core-toolbar>SERVIÇOS</core-toolbar>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="service in services"
          :key="service.title"
          class="d-flex"
        >
          <v-card class="mx-auto d-flex flex-column flex-grow-1">
            <v-img
              v-if="service.image"
              :src="service.image"
              height="14rem"
              class="flex-grow-0 flex-shrink-0"
            ></v-img>
            <v-card-title
              class="display-2 font-weight-medium"
              v-text="service.title"
              style="word-break: break-word;"
            />
            <v-divider />
            <v-card-text
              class="body-1 flex-grow-1 flex-shrink-0"
              v-text="service.description"
            />
            <v-card-actions>
              <v-btn
                dark
                text
                color="#669933"
                :href="`https://api.whatsapp.com/send?phone=5551997311319&text=${encodeURI(service.whatsappMessage)}`"
                target="_blank"
              >
                <v-icon left color="#669933" v-text="'mdi-whatsapp'" />
                Saiba mais
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    CoreToolbar: () => import('@/components/site/core/CoreToolbar'),
  },

  data: () => ({
    services: [
      {
        title: 'Funeral',
        image: 'images/funeral.jpg',
        description:
          'Na HUWE iremos acompanhá-lo neste momento difícil. Vamos ajudá-lo a organizar o último adeus ao seu ente querido de uma forma única, desde o local da morte até ao local de descanso, fornecendo um serviço funerário completo. Também disponibilizamos um serviço para todas as culturas e religiões, com espaços acolhedores, amplos e cuidadosamente mobiliados para celebrações fúnebres em um ambiente de tranquilidade e privacidade.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços funerários.',
      },
      {
        title: 'Translado Internacional E Nacional',
        image: 'images/translado.jpg',
        description:
          'Possuímos veículos especializados para proporcionar o máximo de segurança e rapidez nos traslados. A Huwe providencia e orienta a família para liberação de todos os documentos e trâmites legais necessários, seja por meio terrestre, aéreo, marítimo ou fluvial.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de translado internacional e nacional.',
      },
      {
        title: 'Cremação',
        image: 'images/cremacao.jpg',
        description:
          'A HUWE estará ao seu lado na gestão de todas as práticas relacionadas com a organização da cremação: desde a elaboração de documentos, ao fornecimento de urnas cinerárias e à entrega das cinzas.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de cremação.',
      },
      {
        title: 'Documentação',
        image: 'images/documentacao.jpg',
        description:
          'Visando o seu conforto, nossa equipe vai até você para auxiliar desde a reserva de capela, a escolha do cemitério ou crematório, registro da documentação e escolha do esquife.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de documentação.',
      },
      {
        title: 'Publicação',
        image: 'images/publicacao.jpg',
        description:
          'Publicação em rádios e rede sociais informando à sociedade do falecimento.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de publicação.',
      },
      {
        title: 'Decoração De Capela',
        image: 'images/decoracao.jpg',
        description: 'Preparo da capela velatória com ornamentos específicos.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de decoração de capela.',
      },
      {
        title: 'Tanatopraxia',
        description:
          'É a mais moderna técnica de conservação de corpos, utilizada em quase todos os países do mundo. Quando passamos pelo processo de perda de um ente querido, a última aparência é aquela que fica para sempre na nossa memória. A realização da Tanatopraxia é uma higienização total e conservação ao corpo, onde reduz as transformações próprias que o corpo vai apresentando no “post-mortem”.',
        whatsappMessage:
          'Olá, estou interessado em obter mais informações sobre os serviços de tanatopraxia.',
      },
    ],
  }),
}
</script>
